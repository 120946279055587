@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body
{
    background-color: #fff;
    width: 100%;
    height: 100vh;
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 0.1em;
}

#popup
{
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: url('../images/back.jpg');
    background-color: #014493;
    background-size: cover;
    background-position: center;       
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#pop_center
{
    width: 50%;
    height: auto;
    background-color:rgba(23,70,143, .7);
    padding: 30px;
    border:1px solid #fff;
}

#popup img
{
    width: 60%;
    height: auto;
    margin-bottom: 20px;
}

#popup h1
{
    color: #fff;
    font-size: 5vw !important;
    margin: 0;
    padding: 0;
    margin-bottom:5px;
}

#popup p
{
    color: #fff;
    font-size: 3vw;
    margin: 0;
    padding: 0;
}

.note
{
    font-size: 2vw !important;
}

#top
{
    width: 100%;
    height: auto;
    position: absolute;
    top:0
}

#top #logo_top1
{
    float: left;
    margin-left: 20px;
}

#top #logo_top2
{
    float: right;
    margin-right: 20px;
}

#top img
{
    width: 150px;
    height: auto;
}

#intro
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

#center
{
    width: 50%;
    background-color: #cecece;
    padding: 20px;
    border-bottom: 6px solid #014493;
}

#texto
{
    background-color: #fff;
    border: 1px solid #17468f;
    outline: none;
    width: calc(100% - 20px);
    display: block;
    font-size: 18px;
    padding: 10px;
    margin-bottom: 10px;
    font-family: 'Arial',;
}

#textoBtn
{
    background-color: #17468f;
    color: #fff;
    border: 0;
    outline: 0;
    font-size: 24px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 0.05em;
}

#textoBtn:hover
{
    background-color: #021428;
}

#indi
{
    margin-bottom: 20px;
}

#indi p, #indi h1
{
    margin: 0;
    padding: 0;
}

#indi h1
{
   font-size: 30px;
   color: #17468f;
}

#indi p
{
   font-size: 22px;
   color: #021428;
}

#footer
{
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #17468f;
}

#footer2
{
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #fff;
}

.areaMain
{
    width: 100%;
    height: 100%;
}

.areaVideo
{
    width: calc(80% - 1px);
    height: 100vh;
    border-right: 1px solid #d1d2d4;
    float: left;
    display: flex;
  	align-items: center;
    justify-content: center;
    background: url('../images/back.jpg');
    background-color: #014493;
    background-size: cover;
    background-position: center;       
}

.centerElement
{
    width: 80%;
}

.logo
{
    width:400px;
    height: auto;
}


#logos
{
    margin-bottom:20px;
    width: 100%;
    text-align: center;
}

#vid
{
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
    height: 0;
    margin-top: 25px;
}

#vid iframe
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.areaChat
{
    width: 20%;
    height: 100vh;
    float: right;
    position: relative;
    margin: 0;
    padding: 0;
}

.areaChat iframe
{
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width:1500px) {

    .areaChat
    {
        width: 30%;
    }

    .areaVideo
    {
        width: calc(70% - 1px);
    }

    .centerElement
    {
        width: 90%;
    }

}    

@media screen and (max-width:800px) {

    .areaVideo
    {
        width: 100%;
        height: 40vh;
        display: block;
        border: none;
    }

    #logos
    {
        display: none;
    }

    .centerElement
    {
        width: auto;
        height: 100%;
        background-color: #eef1f4;
    }

    #vid
    {
        position: relative;
        padding-bottom: 0%; /*panorámico*/
        padding-top: 0px;
        height: 100%;
        margin-top: 0;
    }

    #vid iframe
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .areaChat
    {
        width: 100%;
        height: 60vh;
    }

}
